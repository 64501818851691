import { showNotify } from "vant";

const notifyMessage = (type = 'info', message = '', duration = 2000, color = '', background = '') => {
    showNotify({
        type,
        message,
        duration,
        color,
        background
    })
}

const success = (title, duration) => { notifyMessage('success', title, duration, '#ffffff', '#1989fa') }
const error = (title, duration) => { notifyMessage('error', title, duration, '#ffffff', '#ee0a24') }
const warning = (title, duration) => { notifyMessage('warning', title, duration, '#ffffff', '#ff976a') }
const danger = (title, duration) => { notifyMessage('danger', title, duration, '#ffffff', '#ee0a24') }
const info = (title, duration) => { notifyMessage('primary', title, duration, '#ffffff', '#1989fa') }

export { success as NotifyMessageSuccess, warning as NotifyMessageWarning, info as NotifyMessageInfo, error as NotifyMessageError, danger as NotifyMessageDanger }