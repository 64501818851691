import request from '../utils/request'

const agentApi = {
    BenefitMonth: '/app/agent_benefit_month',
    BenefitCollect: '/app/agent_benefit_collect',
    Login: '/app/agent_login',
}

export function AgentBenefitMonth(params) {
    return request({
        url: agentApi.BenefitMonth,
        method: 'get',
        params: params
    });
}
export function AgentBenefitCollect(params) {
    return request({
        url: agentApi.BenefitCollect,
        method: 'get',
        params: params
    });
}
export function AgentLogin(data) {
    return request({
        url: agentApi.Login,
        method: 'post',
        data: { data: data }
    });
}