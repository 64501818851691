import axios from 'axios'
import Router from '../router'
import { NotifyMessageDanger } from './notifyMessage'

// const APP_API_SERVICE_URL = 'https://hangzhou-api.laidianhudong.com/api/v1'
const APP_API_SERVICE_URL = 'https://service.tongchengspa.com/api/v1'
// const APP_API_SERVICE_URL='http://127.0.0.1:8009/api/v1'

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: APP_API_SERVICE_URL,
    timeout: 6000 // 请求超时时间
})

const ACCESS_TOKEN = 'systemToken'

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data
        if (error.response.status === 403 || error.response.status === 500 || error.response.status === 400) {
            NotifyMessageDanger(data.message)
        }
        if (error.response.status === 401) {
            NotifyMessageDanger('登录过期,请重新登录系统')
            Router.replace({
                name: 'Login'
            })
        }
    }
    return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
    const token = localStorage.getItem(ACCESS_TOKEN)
    // 如果 token 存在
    // 让每个请求携带自定义 token 请根据实际情况自行修改
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
}, errorHandler)

// response interceptor
request.interceptors.response.use((response) => {
    return response.data.data
}, errorHandler)

export default request
